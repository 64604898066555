import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

class Salidas extends Component {
  constructor(props) {
    super(props);
    this.onSubmitNothing = this.onSubmitNothing.bind(this);
  }
  
  onSubmitNothing(event) {
    event.preventDefault();
  }
  
  state = {
    username: '',
    password: '',
    filtro: '',
    matrices: [],
    tipo: 'N',
    loading: false,
    imagen: null,
    // Para la ordenación:
    sortColumn: null,
    sortDirection: "asc", // "asc" o "desc"
    // Para mostrar solo matrices tubulares:
    onlyTubulares: false
  };
  
  componentDidMount() {
    this.getData();
  }
  
  doNothing(day) {
    // Función vacía para evitar propagación
  }
  
  // -----------------------------
  // Métodos para ordenar
  handleSort = (column) => {
    let { sortColumn, sortDirection } = this.state;
    if (sortColumn == column) {
      sortDirection = sortDirection == "asc" ? "desc" : "asc";
    } else {
      sortColumn = column;
      sortDirection = "asc";
    }
    this.setState({ sortColumn, sortDirection });
  };

  getSortedMatrices = () => {
    // Primero se filtran según el filtro de texto y el checkbox de matrices tubulares
    let filtered = this.state.matrices.filter((matriz, index) => {
      // Filtro de texto (ya implementado en tu función filtrar)
      const cumpleFiltro = this.filtrar(index);
      // Si el check "solo tubulares" está marcado, comprobamos que la cadena tenga 0 en la sexta posición.
      // Se asume que la columna MATRIZ se compone de: ARTALIAS + '-' + NUMSERIE.
      const matrizStr = `${matriz.ARTALIAS}-${matriz.NUMSERIE}`;
      const cumpleTubular = this.state.onlyTubulares ? (matrizStr.length >= 6 && matrizStr[5] == '0') : true;
      return cumpleFiltro && cumpleTubular;
    });
    
    const { sortColumn, sortDirection } = this.state;
    if (!sortColumn) return filtered;
    
    // Se define la función de comparación
    filtered.sort((a, b) => {
      let aVal, bVal;
      switch(sortColumn) {
        case "CODART":
          aVal = a.CODART || "";
          bVal = b.CODART || "";
          break;
        case "MATRIZ": 
          // Ordenamos por la cadena compuesta ARTALIAS-NUMSERIE
          aVal = `${a.ARTALIAS}-${a.NUMSERIE}` || "";
          bVal = `${b.ARTALIAS}-${b.NUMSERIE}` || "";
          break;
        case "DESCART":
          aVal = a.DESCART || "";
          bVal = b.DESCART || "";
          break;
        case "KG":
          aVal = Number(a.KG) || 0;
          bVal = Number(b.KG) || 0;
          break;
        case "PESO":
          // Comparamos el valor actual (o el teórico si es "-1")
          aVal = a.PESO != "-1" ? Number(a.PESO) : Number(a.PESO_TEORICO);
          bVal = b.PESO != "-1" ? Number(b.PESO) : Number(b.PESO_TEORICO);
          break;
        case "VOLUMEN":
          aVal = Number(a.VOLUMEN) || 0;
          bVal = Number(b.VOLUMEN) || 0;
          break;
        default:
          aVal = "";
          bVal = "";
      }
      
      if (typeof aVal == "number" && typeof bVal == "number") {
        return sortDirection == "asc" ? aVal - bVal : bVal - aVal;
      } else {
        aVal = aVal.toString().toLowerCase();
        bVal = bVal.toString().toLowerCase();
        if (aVal < bVal) return sortDirection == "asc" ? -1 : 1;
        if (aVal > bVal) return sortDirection == "asc" ? 1 : -1;
        return 0;
      }
    });
    
    return filtered;
  };
  // -----------------------------

  async getData() {
    this.setState({ loading: true });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }
    
    fetch("http://192.168.0.19:49264/api/modulo/extrusion/get_matrices", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        // Se asigna la matriz
        this.state.matrices = result[0];
        this.state.matrices.forEach(matriz => {
          /*if (matriz.SERIE_DEFECTO == matriz.NUMSERIE)
            this.getImagenMatriz(matriz);*/
          if (matriz.PESO == '0')
            matriz.PESO = '-1';
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        alert(error);
        this.setState({ loading: false });
        console.error(error);
      });
  }
  
  async getImagenesMatriz(){
	   this.state.matrices.forEach(matriz => {
          if (matriz.SERIE_DEFECTO == matriz.NUMSERIE && matriz.IMAGEN==null && !matriz.LOADINGIMAGE)
            this.getImagenMatriz(matriz);
        });
  }
  
  async getImagenMatriz(matriz) {
    matriz.LOADINGIMAGE = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: matriz.CODART,
    };
	
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }
	
    
    fetch("http://192.168.0.19:49264/api/modulo/fabricacion/get_imagen_articulo", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        matriz.LOADINGIMAGE = false;
        if (result.IMAGEN != 'NO')
          matriz.IMAGEN = result.IMAGEN;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        alert(error);
        matriz.LOADINGIMAGE = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  addRegistro(index) {
    this.state.matrices[index].ADD_clicked = true;
    this.state.matrices[index].ADD = !this.state.matrices[index].ADD;
    this.setState({ state: this.state });
  }
  
  eliminarRegistro(index, ind) {
    window.confirm('¿Estás seguro? Vas a eliminar un registro.')
      ? this.doEliminarRegistro(index, ind)
      : this.doNothing();
  }
  
  async doEliminarRegistro(index, ind) {
    this.state.matrices[index].CARGANDO = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      NUMSERIE: this.state.matrices[index].NUMSERIE,
      ID: this.state.matrices[index].LINEAS[ind].ID,
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/eliminar_registro_matriz", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.length == 0) {
          alert("Se ha producido algún error");
        } else {
          this.state.matrices[index].LINEAS = result[0];
          this.state.matrices[index].OPEN = true;
          this.state.matrices[index].SEARCH = true;
          this.state.matrices[index].ADD = false;
        }
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  async doAddRegistro(index) {
    if (
      this.state.matrices[index].NUEVAS_OBSERVACIONES == null ||
      this.state.matrices[index].NUEVAS_OBSERVACIONES.length == 0
    ) {
      alert("Introduce primero unas observaciones");
      return;
    }
    
    this.state.matrices[index].CARGANDO = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      NUMSERIE: this.state.matrices[index].NUMSERIE,
      OBSERVACIONES: this.state.matrices[index].NUEVAS_OBSERVACIONES,
      TIPO: this.state.tipo,
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/add_registro_matriz", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.length == 0) {
          alert("Se ha producido algún error");
        } else {
          this.state.matrices[index].LINEAS = result[0];
          this.state.matrices[index].OPEN = true;
          this.state.matrices[index].SEARCH = true;
          this.state.matrices[index].ADD = false;
        }
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  async getLineas(index) {
    if (this.state.matrices[index].NUMSERIE == '')
      return;
    
    if (this.state.matrices[index].ADD_clicked) {
      this.state.matrices[index].ADD_clicked = false;
      return;
    }
    
    if (this.state.matrices[index].SEARCH) {
      this.state.matrices[index].OPEN = !this.state.matrices[index].OPEN;
      this.setState({ state: this.state });
      return;
    }
    
    this.state.matrices[index].CARGANDO = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      NUMSERIE: this.state.matrices[index].NUMSERIE,
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/get_registros_matrices", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.length == 0) {
          alert("Se ha producido algún error");
        } else {
          this.state.matrices[index].LINEAS = result[0];
          this.state.matrices[index].OPEN = true;
          this.state.matrices[index].SEARCH = true;
        }
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        this.state.matrices[index].CARGANDO = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  // Filtro original por CODART, DESCART o ARTALIAS
  filtrar(index) {
    let salida = this.state.matrices[index];
    let FILTRO = this.state.filtro.toUpperCase();
    return (
      salida.CODART.includes(FILTRO) ||
      salida.DESCART.includes(FILTRO) ||
      salida.ARTALIAS.includes(FILTRO)
    );
  }
  
  altaMatriz(index) {
    this.state.matrices[index].ADD_clicked = true;
    window.confirm(
      '¿Estás seguro? Vas a dar de alta una nueva matriz de este código y la existente se marcará como obsoleta. La nueva matriz será la ' +
      this.state.matrices[index].ARTALIAS + '-' + (parseInt(this.state.matrices[index].SERIE_DEFECTO) + 1)
    )
      ? this.doAltaMatriz(index)
      : this.doNothing();
  }
  
  async doAltaMatriz(index) {
    this.setState({ loading: true });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      NUMSERIE: parseInt(this.state.matrices[index].SERIE_DEFECTO) + 1,
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/nueva_matriz", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.DONE != 1) {
          alert("Se ha producido algún error");
        } else {
          this.getData();
          return;
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        alert("Se ha producido algún error");
        this.setState({ loading: false });
        console.error(error);
      });
  }
  
  async abrirPlano(ARTALIAS, index) {
    this.state.matrices[index].ADD_clicked = true;
    let bodyData = {
      path: "\\\\SERVER001\\Forta\\Extrusión\\PLANOS\\PLANOS PERFILES\\" + ARTALIAS + ".pdf",
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }
    
    fetch("http://192.168.0.19:49264/api/modulo/manuales/descargar_PDF", data)
      .then(result => result.json())
      .then(result => {
        if (result.DONE != 1) {
          alert("No se encuentra el plano solicitado");
          return;
        }
        // Se abre el PDF en una nueva ventana
        var base64str = result.PDF;
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        window.open(url);
      })
      .catch((error) => {
        this.setState({ loading: false });
        alert("ERROR DE RED");
        console.error(error);
      });
  }
  
  getTipo(TIPO) {
    switch (TIPO) {
      case 'O': return 'OTROS';
      case 'EXTRUSIONADO': return 'EXTRUSIONADO';
      case 'N': return 'NITRURADO';
      case 'B': return 'BAJA';
      default: return TIPO;
    }
  }
  
  async guardarPeso(index) {
    this.state.matrices[index].ADD_clicked = true;
    this.state.matrices[index].LOADINGPESO = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      PESO: this.state.matrices[index].PESO,
      NUMSERIE: this.state.matrices[index].NUMSERIE,
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/guardar_peso", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.DONE != 1) {
          alert("Se ha producido algún error");
        } else {
          this.state.matrices[index].PESO_ORI = this.state.matrices[index].PESO;
        }
        this.state.matrices[index].LOADINGPESO = false;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        alert("Se ha producido algún error");
        this.state.matrices[index].LOADINGPESO = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  async guardarVolumen(index) {
    this.state.matrices[index].ADD_clicked = true;
    this.state.matrices[index].LOADINGVOLUMEN = true;
    this.setState({ state: this.state });
    
    let bodyData = {
      user: sessionStorage.getItem('user'),
      CODART: this.state.matrices[index].CODART,
      VOLUMEN: this.state.matrices[index].VOLUMEN
    };
    
    let data = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(bodyData)
    }

    fetch("http://192.168.0.19:49264/api/modulo/extrusion/guardar_volumen", data)
      .then(result => result.json())
      .then(result => {
        if (result[0] != null && result[0].token == 0) {
          alert('Renovando sesión...');
          window.location.reload(false);
        }
        if (result.DONE != 1) {
          alert("Se ha producido algún error");
        } else {
          this.state.matrices[index].VOLUMEN_ORI = this.state.matrices[index].VOLUMEN;
        }
        this.state.matrices[index].LOADINGVOLUMEN = false;
        this.setState({ state: this.state });
      })
      .catch((error) => {
        alert("Se ha producido algún error");
        this.state.matrices[index].LOADINGVOLUMEN = false;
        this.setState({ state: this.state });
        console.error(error);
      });
  }
  
  render() {
    const sortedMatrices = this.getSortedMatrices();

    return (
      <div className="">
        {/* Imagen ampliada */}
        {this.state.imagen != null && (
          <>
            <div style={{ position: "fixed", width: "85%", marginTop: -250, zIndex: 99 }}>
              <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ imagen: null })}>
                <img
                  alt="Imagen ampliada"
                  src={this.state.imagen}
                  style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "65%" }}
                />
              </a>
            </div>
            <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ imagen: null })}>
              <div style={{
                position: "fixed",
                width: "300%",
                height: "300%",
                margin: -300,
                paddingRight: 260,
                backgroundColor: '#ccc',
                zIndex: 98,
                opacity: 0.9
              }}>
              </div>
            </a>
          </>
        )}
        
        {/* Filtro y checkbox para matrices tubulares */}
        <div className="d-flex justify-content-end align-items-center" style={{marginBottom:"-35px"}}>
          <form onSubmit={this.onSubmitNothing} className="mr-3 pr-3" style={{marginRight:"10px"}}>
            <input
              type="text"
              value={this.state.filtro}
              onChange={(e) => this.setState({ filtro: e.target.value })}
              placeholder="Filtro"
              className="form-control"
            />
          </form>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="tubularesCheck"
              checked={this.state.onlyTubulares}
              onChange={(e) => this.setState({ onlyTubulares: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="tubularesCheck">
              Solo matrices tubulares
            </label>
          </div>
        </div>
        
        <h2 className="mb-4">MATRICES</h2>
        
        {this.state.loading && (
          <div className="text-center">
            <img alt="Loading..." src={loadingGif} style={{ height: 350 }} />
          </div>
        )}
        
        {!this.state.loading && sortedMatrices.length > 0 && (
          <table className="table table-striped table-hover">
            <thead style={{ backgroundColor: "#339999", color: "#fff" }}>
              <tr>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("CODART")}>
                  COD. MATRIZ {this.state.sortColumn == "CODART" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("MATRIZ")}>
                  MATRIZ {this.state.sortColumn == "MATRIZ" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("DESCART")}>
                  DESCRIPCIÓN {this.state.sortColumn == "DESCART" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("KG")}>
                  KG TOTAL {this.state.sortColumn == "KG" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("PESO")}>
                  PESO {this.state.sortColumn == "PESO" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th style={{ cursor: "pointer" }} onClick={() => this.handleSort("VOLUMEN")}>
                  LONG. CORTE {this.state.sortColumn == "VOLUMEN" && (this.state.sortDirection == "asc" ? "↑" : "↓")}
                </th>
                <th>
				{"Imagen   "}
					<button type="button" className="btn btn-sm btn-secondary" onClick={() => {this.getImagenesMatriz()}}>
						VER TODAS
					</button>
				</th>
                <th>Nueva Matriz</th>
                <th>Plano</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sortedMatrices.map(({
                PESO_TEORICO, LOADINGPESO, LOADINGVOLUMEN, LOADINGIMAGE, PESO_ORI, VOLUMEN_ORI,
                IMAGEN, SERIE_DEFECTO, ARTALIAS, DESCART, KG, CODART, NUMSERIE, UNIDADES,
                OPEN, SEARCH, CARGANDO, LINEAS, ADD, NUEVAS_OBSERVACIONES, ADD_clicked, PESO, VOLUMEN
              }, index, matriz) => (
                // Se utiliza la función filtrar interna en getSortedMatrices, por lo que aquí directamente se renderiza
                <React.Fragment key={index}>
                  <tr style={{
                    backgroundColor: NUMSERIE == '' ? "#ffebeb" : UNIDADES > 0 ? "#FFF" : "#CCC"
                  }}>
                    <td>{CODART}</td>
                    <td>{ARTALIAS}-{NUMSERIE}</td>
                    <td>{DESCART}</td>
                    <td>{Number(KG).toFixed(0)} kg</td>
                    {!LOADINGPESO && NUMSERIE != '' ? (
                      <td>
                        <label>
                          <input
                            onClick={() => {
                              this.state.matrices[index].ADD_clicked = true;
                              this.setState({ state: this.state });
                            }}
                            type="number"
                            value={PESO != '-1' ? PESO + "" : PESO_TEORICO + ""}
                            onChange={(e) => { this.state.matrices[index].PESO = e.target.value; this.setState({ state: this.state }); }}
                            placeholder={PESO_ORI + ""}
                            style={{ marginRight: 10, width: 70 }}
                            min="0"
                            className="form-control d-inline-block"
                          />
                        </label>
                        kg/m {PESO != PESO_ORI && PESO != '' && PESO != '-1' && PESO != '0' && (
                          <>
                            <button type="button" onClick={() => { this.guardarPeso(index) }} style={{ height: 34 }} className="btn btn-sm btn-primary ml-1">
                              GUARDAR
                            </button>
                            <button type="button" onClick={() => { this.state.matrices[index].PESO = PESO_ORI; this.state.matrices[index].ADD_clicked = true; this.setState({ state: this.state }) }} style={{ height: 34, marginLeft: 5 }} className="btn btn-sm btn-danger">
                              X
                            </button>
                          </>
                        )}
                      </td>
                    ) : NUMSERIE == '' ? (
                      <td>Crea una nueva matriz</td>
                    ) : LOADINGPESO ? (
                      <td>
                        {PESO} kg/m <img alt="Loading..." src={loadingGif} style={{ height: 50, margin: "-20px 0" }} />
                      </td>
                    ) : null}
                    {SERIE_DEFECTO == NUMSERIE && !LOADINGVOLUMEN ? (
                      <td>
                        <label>
                          <input
                            onClick={() => { this.state.matrices[index].ADD_clicked = true; this.setState({ state: this.state }) }}
                            type="number"
                            value={VOLUMEN + ""}
                            onChange={(e) => { this.state.matrices[index].VOLUMEN = e.target.value; this.setState({ state: this.state }) }}
                            placeholder={VOLUMEN_ORI + ""}
                            style={{ marginRight: 10, width: 70 }}
                            min="0"
                            className="form-control d-inline-block"
                          />
                        </label>
                        m {VOLUMEN != VOLUMEN_ORI && VOLUMEN != '' && VOLUMEN != '0' && (
                          <>
                            <button type="button" onClick={() => { this.guardarVolumen(index) }} style={{ height: 34 }} className="btn btn-sm btn-primary ml-1">
                              GUARDAR
                            </button>
                            <button type="button" onClick={() => { this.state.matrices[index].VOLUMEN = VOLUMEN_ORI; this.state.matrices[index].ADD_clicked = true; this.setState({ state: this.state }) }} style={{ height: 34, marginLeft: 5 }} className="btn btn-sm btn-danger">
                              X
                            </button>
                          </>
                        )}
                      </td>
                    ) : LOADINGVOLUMEN ? (
                      <td>
                        {VOLUMEN} m <img alt="Loading..." src={loadingGif} style={{ height: 50, margin: "-20px 0" }} />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    { !LOADINGIMAGE && IMAGEN != null ? (
                      <td>
                        <a style={{ cursor: 'pointer' }} onClick={() => { this.state.matrices[index].ADD_clicked = true; this.setState({ imagen: IMAGEN }) }}>
                          <img src={IMAGEN} style={{ width: 150 }} alt="Matriz" />
                        </a>
                      </td>
                    ) : SERIE_DEFECTO == NUMSERIE && !LOADINGIMAGE && IMAGEN == null ? (
						<td onClick={() => this.doNothing()}>
							<button type="button" className="btn btn-sm btn-secondary" onClick={() => {this.getImagenMatriz(matriz[index])}}>
								VER
							</button>
						</td>
                    ) : LOADINGIMAGE ? (
                      <td>
                        <div style={{ textAlign: "center" }}>
                          <img alt="Loading..." src={loadingGif} style={{ height: 50, margin: "-20px 0" }} />
                        </div>
                      </td>
                    ) : <td></td>}
                    <td onClick={() => this.doNothing()}>
                      {SERIE_DEFECTO == NUMSERIE && (
                        <button type="button" onClick={() => this.altaMatriz(index)} style={{ height: 34 }} className="btn btn-sm btn-info">
                          NUEVA
                        </button>
                      )}
                    </td>
                    <td onClick={() => this.doNothing()}>
                      <button type="button" onClick={() => this.abrirPlano(ARTALIAS, index)} style={{ height: 34 }} className="btn btn-sm btn-secondary">
                        PLANO
                      </button>
                    </td>
                    <td onClick={() => this.doNothing()}>
                      <button style={{ marginRight: 5, float: 'right', height: 34, width: 34 }} type="button" onClick={() => this.addRegistro(index)} className="btn btn-sm btn-dark">
                        {!ADD ? '+' : '-'}
                      </button>
					  <button style={{ marginRight: 5, float: 'right', height: 34, width: 34 }} type="button" onClick={() => this.getLineas(index)} className="btn btn-sm btn-dark">
						<span style={{ display: 'inline-block', transform: 'rotate(90deg)' }}>
						  {!OPEN ? '>' : '<'}
						</span>
                      </button>
                    </td>
                  </tr>
                  {CARGANDO && (
                    <tr style={{ cursor: "auto" }}>
                      <td colSpan="10">
                        <div style={{ textAlign: "center" }}>
                          <img alt="Loading..." src={loadingGif} style={{ height: 50, margin: "-20px 0" }} />
                        </div>
                      </td>
                    </tr>
                  )}
                  {OPEN && LINEAS.length == 0 && (
                    <tr>
                      <td colSpan="10" style={{ padding: 0, textAlign: 'center', backgroundColor: '#eee', color: "#000", cursor: "auto" }}>
                        <p>NO HAY REGISTROS NI EXTRUSIONES DE ESTA MATRIZ</p>
                      </td>
                    </tr>
                  )}
                  {OPEN && LINEAS.length > 0 && (
                    <tr>
                      <td colSpan="10" style={{ padding: 0 }}>
                        <table className="table">
                          <tbody>
                            {LINEAS.map(({ FECHA, OBSERVACIONES, TIPO }, ind) => (
                              <React.Fragment key={ind}>
                                {ind == 0 && (
                                  <tr style={{ backgroundColor: "#339999", color: "#fff", cursor: "auto" }}>
                                    <th>FECHA</th>
                                    <th>TIPO DE REGISTRO</th>
                                    <th>OBSERVACIONES</th>
                                    <th style={{ width: 60 }}></th>
                                  </tr>
                                )}
                                <tr style={{ backgroundColor: "#eee", color: "#000", cursor: "auto" }}>
                                  <td>{FECHA}</td>
                                  <td>{this.getTipo(TIPO)}</td>
                                  <td>{TIPO == 'EXTRUSIONADO' ? OBSERVACIONES + ' kg' : OBSERVACIONES}</td>
                                  <td>
                                    {TIPO != 'EXTRUSIONADO' && TIPO != 'B' && (
                                      <button type="button" onClick={() => this.eliminarRegistro(index, ind)} style={{ height: 34 }} className="btn btn-sm btn-warning">
                                        ELIMINAR
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  {ADD && (
                    <tr style={{ cursor: "auto", backgroundColor: "#cdeeee", color: "#000" }}>
                      <td colSpan="9" style={{ padding: 10 }}>
                        <b>OBSERVACIONES DEL NUEVO REGISTRO: </b>
                        <textarea
                          value={NUEVAS_OBSERVACIONES == null ? '' : NUEVAS_OBSERVACIONES}
                          onChange={(e) => { this.state.matrices[index].NUEVAS_OBSERVACIONES = e.target.value; this.setState({ state: this.state }); }}
                          style={{ marginLeft: 10, width: 800, marginBottom: -18, height: 50 }}
                          className="form-control"
                        />
                        <select name="tareas" id="tareas" onChange={(e) => this.setState({ tipo: e.target.value })} style={{ marginLeft: 10, marginRight: 5 }} className="form-control d-inline-block" >
                          <option value="N">Nitrurado</option>
                          <option value="B">Baja de matriz</option>
                          <option value="O">Otro</option>
                        </select>
                        <button style={{ marginLeft: 5, fontSize: 24, marginBottom: 10 }} type="button" onClick={() => this.doAddRegistro(index)} className="btn btn-sm btn-success">
                          CREAR REGISTRO
                        </button>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
 
export default Salidas;
