import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Inventarios from "./Inventarios";
import Salidas from "./Salidas";
import Sillas from "./Sillas";
import Cronograma from "./Cronograma";
import Acliente from "./Acliente";
import Extrusion from "./Extrusion";
import Tareas from "./Tareas";
import HistoricoExtrusion from "./HistoricoExtrusion";
import Matrices from "./Matrices";
import PedidosExtrusion from "./PedidosExtrusion";
import Mapa from "./Mapa";
import NoConformidad from "./NoConformidad";
import Calendario from "./Calendario";
import StockArticulo from "./StockArticulo";
import Incidencias from "./Incidencias";
import VariosInventario from "./VariosInventario";
import EPIsCompras from "./EPIsCompras";
import Moldes from "./Moldes";
import Lotes from "./Lotes";
import Costes from "./Costes";
import TablaCostes from "./TablaCostes";
import Programacion from "./Programacion"
import OrdenesInternas from "./OrdenesInternas"
import CuadroMando from "./CuadroMando"
import PersonalFabrica from "./PersonalFabrica"
import AlmacenVirtual from "./AlmacenVirtual"
import logoutIcon from './assets/logout_icon.png';
import fortaLogo from './assets/forta.png';
import fortaLogoDocuments from './assets/forta_logo_OG.png';
import miexalLogoDocuments from './assets/miexal_logo_OG.png';
 
 
class Main extends Component {
	
	constructor(props) {
		super(props);
		this.state = {username: '',password: '',loading:true,loggedIn:false};

		this.handleUserChange = this.handleUserChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	componentDidMount(){
		const queryParams = new URLSearchParams(window.location.search);
		const pdf = queryParams.get('pdf');
		if(pdf==null)
			this.checkLogin();
		else{
			this.state.descargando = true;
			this.generarPDF(pdf);
		}
	}
	
	async generarPDF(pdf){
		this.setState({loading:true });
		console.log(pdf);
		let bodyData = {
			path: "MANUALES/"+pdf,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/manuales/descargar_PDF",data)
							.then(result => result.blob())
							.then(result => {
								const file = new Blob([result], {
								  type: "application/pdf"
								});
								//Build a URL from the file
								const fileURL = URL.createObjectURL(file);
								//Open the URL on new Window
								window.open(fileURL);
								//window.close(); //o esto o un texto que indique "DESCARGANDO ARCHIVO" y ya está.
								this.setState({loading: false });
								return;
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado!=0){
									alert('Hecho');
									this.setState({loading: false });
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({loading: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}

	handleUserChange(event) {
		this.setState({username: event.target.value.toUpperCase()});
	}
	
	handlePasswordChange(event){
		this.setState({password: event.target.value});
	}

	handleSubmit(event) {
		this.onLogin();
		event.preventDefault();
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	logout(){
		this.removeSessionStorage();
		this.setState({username: '',password: '',loading:true,loggedIn:false});
	}
	
  render() {
	
	if(this.state.descargando)
		return (
			<div style={{height:"100%"}}>
				<div className="preLogin">
					<div className="login">
						<p><b>DESCARGANDO ARCHIVO...</b></p>
					</div>
				</div>
			</div>
		);
	
	
	
	if(this.state.loggedIn)
		return (
		  <HashRouter>
			<div>
				{true&&
				<div>
					<div className="content">
						<a href="#" className="floatRight" onClick={()=>this.logout()}><img src={logoutIcon} alt="logo" /></a>
						<img alt="FORTA" src={fortaLogo} style={{height:55}}/>
					</div>
					<ul className="header">
						{(sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='JULIO'||sessionStorage.getItem('user')=='PACO INGENIERIA'||sessionStorage.getItem('user')=='PACO GERENCIA')&&
							<li><NavLink to="/mapa">MAPA</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/extrusion">EXTRUSIÓN</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/historico_extrusion">HISTÓRICO</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/matrices">MATRICES</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/pedidosExtrusion">PEDIDOS</NavLink></li>
						}
						{sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&
							<li><NavLink to="/tareas">TAREAS</NavLink></li>
						}
						{sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/incidencias">INCIDENCIAS</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='COMPRAS'||sessionStorage.getItem('user')=='NOEMI'||sessionStorage.getItem('user')=='PACO INGENIERIA'||sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='PACO ALCÁNTARA')&&
							<li><NavLink to="/variosInventario">VARIOS INVENTARIO</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='COMPRAS'||sessionStorage.getItem('user')=='JAIME')&&
							<li><NavLink to="/EPIsCompras">STOCK EPIs</NavLink></li>
						}
						{sessionStorage.getItem('user')!='GUILLERMO'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink exact to="/entradas">ENTRADA DE MATERIAL</NavLink></li>
						}
						{sessionStorage.getItem('user')!='GUILLERMO'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/salidas">ÓRDENES DE SALIDA</NavLink></li>
						}
						{sessionStorage.getItem('user')!='GUILLERMO'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/inventarios">INVENTARIOS INYECCIÓN</NavLink></li>
						}
						
						{sessionStorage.getItem('user')!='COMPRAS'&&sessionStorage.getItem('user')!='GUILLERMO'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/cronograma">CRONOGRAMAS</NavLink></li>
						}
						{sessionStorage.getItem('user')!='COMPRAS'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/moldes">MOLDES</NavLink></li>
						}
						{sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/sillas">BÚSQUEDA NºSERIE</NavLink></li>
						}
						{sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='COMPRAS'&&sessionStorage.getItem('user')!='MERCE'&&sessionStorage.getItem('user')!='GUILLERMO'&&sessionStorage.getItem('user')!='ANGEL'&&
							<li><NavLink to="/calendario">RECURSOS HUMANOS</NavLink></li>
						}
						{sessionStorage.getItem('user')!='EXTRUSION'&&sessionStorage.getItem('user')!='ANGEL'&&sessionStorage.getItem('user')!='MIRIAM'&&
							<li><NavLink to="/stockArticulo">PREVISIÓN</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='PACO INGENIERIA'||sessionStorage.getItem('user')=='JULIO')&&
							<li><NavLink to="/costes">COSTES</NavLink></li>
						}
						{sessionStorage.getItem('user')=='JAIME'&&
							<li><NavLink to="/tablacostes">TABLA COSTES</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/programacion">PROGRAMACIÓN</NavLink></li>
						}
						{sessionStorage.getItem('user')=='EXTRUSION'&&
							<li><NavLink to="/ordenes_internas">ÓRDENES INTERNAS</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='PACO INGENIERIA'||sessionStorage.getItem('user')=='PACO ALCÁNTARA')&&
							<li><NavLink to="/cuadroMando">CUADRO DE MANDO</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='PACO INGENIERIA'||sessionStorage.getItem('user')=='PACO ALCÁNTARA')&&
							<li><NavLink to="/personalFabrica">PERSONAL FÁBRICA</NavLink></li>
						}
						{(sessionStorage.getItem('user')=='JAIME'||sessionStorage.getItem('user')=='MERCE'||sessionStorage.getItem('user')=='JULIO')&&
							<li><NavLink to="/almacenVirtual">ALMACÉN VIRTUAL</NavLink></li>
						}
						
						{/*<li><NavLink to="/stuff">Stuff</NavLink></li>
						<li><NavLink to="/contact">Contact</NavLink></li>
						
						<li><NavLink to="/acliente">Atención al cliente</NavLink></li>
						<li><NavLink to="/sillas">Sillas</NavLink></li>
						<li><NavLink to="/lotes">Control de lotes</NavLink></li>
						*/}
					</ul>
				</div>
				}
				<div className="content">
					<Route exact path="/tareas" component={Tareas}/>
					<Route path="/extrusion" component={Extrusion}/>
					<Route path="/historico_extrusion" component={HistoricoExtrusion}/>
					<Route path="/matrices" component={Matrices}/>
					<Route path="/pedidosExtrusion" component={PedidosExtrusion}/>
					<Route path="/mapa" component={Mapa}/>
					<Route exact path="/NoConformidad" component={NoConformidad}/>
					<Route path="/entradas" component={Home}/>
					<Route path="/inventarios" component={Inventarios}/>
					<Route path="/incidencias" component={Incidencias}/>
					<Route path="/variosInventario" component={VariosInventario}/>
					<Route path="/EPIsCompras" component={EPIsCompras}/>
					<Route path="/moldes" component={Moldes}/>
					<Route path="/cronograma" component={Cronograma}/>
					<Route path="/salidas" component={Salidas}/>
					<Route path="/sillas" component={Sillas}/>
					<Route path="/calendario" component={Calendario}/>
					<Route path="/stockArticulo" component={StockArticulo}/>
					<Route path="/acliente" component={Acliente}/>
					<Route path="/lotes" component={Lotes}/>
					<Route path="/costes" component={Costes}/>
					<Route path="/tablacostes" component={TablaCostes}/>
					<Route path="/programacion" component={Programacion}/>
					<Route path="/ordenes_internas" component={OrdenesInternas}/>
					<Route path="/cuadroMando"  component={CuadroMando}/>
					<Route path="/personalFabrica"  component={PersonalFabrica}/>
					<Route path="/almacenVirtual"  component={AlmacenVirtual}/>
					
				</div>
			  
			  
			</div>
		  </HashRouter>
		);
	else
		return (
			<div style={{height:"100%"}}>
				<div className="preLogin">
					<div className="login">
						<img alt="FORTA" src={fortaLogo} style={{height:55,marginBottom:20}}/>
						<form onSubmit={this.handleSubmit}>
							<label>
								Usuario: 
								<input type="text" value={this.state.username} onChange={this.handleUserChange} style={{marginLeft:5}} />
							</label>
							<p></p>
							<label className="inputLogin">
								Contraseña: 
								<input type="password" value={this.state.password} onChange={this.handlePasswordChange} style={{marginLeft:5}} />
							</label>
							<p></p>
							<input type="submit" value="INICIAR SESIÓN"/>
						</form>
					</div>
				</div>
			</div>
		);
  }
  
  
  removeSessionStorage(){
		sessionStorage.setItem('user',null);
		sessionStorage.setItem('token',null);
	}
	
	goHome(){
		this.setState({loggedIn: true});
	}
	
	async checkLogin() {
		
		if(sessionStorage.getItem('user')===null ||sessionStorage.getItem('token')===null){
			this.setState({loading: false});
			return;
		}
		else
		{
			let bodyData = {
				user: sessionStorage.getItem('user'),
            };
			
			let data ={
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+sessionStorage.getItem('token'),
				},
				body: JSON.stringify(bodyData)
			}
			
			fetch(await "http://192.168.0.19:49264"+"/api/checkLogin",data)
								.then(result => result.json())
								.then(result => {
									var serverAnswer = result.answer;
									
									if(serverAnswer===1){
										//DATOS CORRECTOS
										this.goHome();
									}
									else{
										//DATOS INCORRECTOS, BORRAMOS LOCALSTORAGE
										this.removeSessionStorage();
										this.setState({loading: false});
									}
								})
								
			.catch((error) => {
				alert('No se puede conectar a la base de datos');
				console.error(error);
			});
		
		
		}
	}
	
	async onLogin() {
		
		const { username, password } = this.state;
		
		if(username==='' ||password==='') alert('Rellene todos los campos...');
		else
		{
			
			let bodyData = {
				user: `${username}`,
				password: `${password}`,
				ip: "http://192.168.0.19:49264"
            };
			
			let data ={
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(bodyData)
			}
			
			fetch(await "http://192.168.0.19:49264"+"/api/login",data)
								.then(result => result.json())
								.then(result => {
									this.state.serverAnswer = result.answer;
									if(this.state.serverAnswer===1){
										//DATOS CORRECTOS
										sessionStorage.setItem('user',`${username}`);
										sessionStorage.setItem('token',result.token);
										this.goHome();
									}
									else{
										this.removeSessionStorage();
										if(this.state.serverAnswer===0)
											alert('Datos incorrectos');
										else
										{
											if(this.state.serverAnswer===2)
												alert('La cuenta no está activada, contacte con Forta');
											else alert('No podemos conectar a la base de datos');
										}
									}
								})
								
			.catch((error) => {
				alert('No se puede conectar a la base de datos');
				console.error(error);
				this.state.checkingDDBB= false;
				this.state.loading = false;
				this.setState({state: this.state });
			});
		
		
		}
	}
  
}
 
export default Main;