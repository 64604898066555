import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loading:true,
		clientes: [],
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	async getData(event){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/listado_clientes/almacenes_virtuales",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1)
									this.state.clientes = result.CLIENTES;
								else alert("Se ha producido algún error...")
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}

	async getLineas(index){
		
		if(this.state.clientes[index].LINEAS!=null){
			this.state.clientes[index].OPEN = !this.state.clientes[index].OPEN;
			this.setState({state:this.state});
			return;
		}

		this.state.clientes[index].LOADING = true;
		this.setState({state:this.state});


		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODCLI: this.state.clientes[index].CODCLI,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/listado_clientes/get_lineas_virtuales",data)
							.then(result => result.json())
							.then(result => {
								if(result.DONE==1){
									if(this.state.clientes[index].CODCLI==88888){
										var data = result.LINEAS;
										const groupedData = data.reduce((acc, row) => {
											// Buscar o crear el grupo para CAR5
											let groupCar5 = acc.find(item => item.CAR5 === row.CAR5);
											if (!groupCar5) {
											groupCar5 = { 
												CAR5: row.CAR5, 
												VIRTUALES: 0, 
												RESTO_VIRTUALES: 0,
												PRODUCTOS: [] 
											};
											acc.push(groupCar5);
											}
										
											// Buscar o crear el subgrupo para CAR8 dentro de cada grupo CAR5
											let groupCar8 = groupCar5.PRODUCTOS.find(item => item.CAR8 === row.CAR8);
											if (!groupCar8) {
											groupCar8 = { 
												CAR8: row.CAR8, 
												VIRTUALES: 0, 
												RESTO_VIRTUALES: 0,
												PRODUCTOS: [] 
											};
											groupCar5.PRODUCTOS.push(groupCar8);
											}
										
											// Crear el producto a agregar
											const producto = {
												CODART: row.CODART,
												DESCLIN: row.DESCLIN,
												VIRTUALES: row.VIRTUALES,
												RESTO_VIRTUALES: row.RESTO_VIRTUALES
											};
										
											// Agregar el producto al grupo de CAR8
											groupCar8.PRODUCTOS.push(producto);
											// Acumular los valores en el nivel de CAR8
											groupCar8.VIRTUALES += row.VIRTUALES;
											groupCar8.RESTO_VIRTUALES += row.RESTO_VIRTUALES;
										
											// Acumular los valores en el nivel de CAR5
											groupCar5.VIRTUALES += row.VIRTUALES;
											groupCar5.RESTO_VIRTUALES += row.RESTO_VIRTUALES;
										
											return acc;
										}, []);
									
									this.state.clientes[index].LINEAS = groupedData;
									}
									else this.state.clientes[index].LINEAS = result.LINEAS;
									this.state.clientes[index].LOADING = false;
									this.state.clientes[index].OPEN = true;
								}
								else alert("Se ha producido algún error buscando las líneas...")
								this.setState({loading: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<h2 style={{marginBottom:25}}>LISTADO DE ALMACENES VIRTUALES</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.clientes.length>0&&<a>
				<table className="tablaEntrada" style={{ marginBottom: "20px" }}>
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"10%"}}>
							CÓDIGO CLIENTE
						</td>
						<td style={{width:"45%"}}>
							NOMBRE COMERCIAL
						</td>
						<td style={{width:"45%"}}>
							RAZÓN SOCIAL
						</td>
					</tr>
					</table>
				{this.state.clientes.map(({ CODCLI, NOMCLI, RAZON, OPEN, LINEAS, LOADING }, index) => (
  <div key={index} style={{ marginTop: "-20px" }}>
    {/* Encabezado del cliente */}
    <table className="tablaEntrada" style={{ backgroundColor: "#FFF", width: "100%" }}>
      <tbody>
        <tr onClick={() => this.getLineas(index)} style={{ cursor: "pointer" }}>
          <td style={{ width: "10%" }}>{CODCLI}</td>
          <td style={{ width: "45%" }}>{NOMCLI}</td>
          <td style={{ width: "45%" }}>{RAZON}</td>
        </tr>
      </tbody>
    </table>

    {/* Líneas asociadas al cliente */}
    <table className="tablaLineas" style={{ width: "100%", marginBottom: "1em" }}>
      <tbody>
        {/* Si se está cargando se muestra un spinner */}
        {LOADING && (
          <tr>
            <td colSpan="5" style={{ textAlign: "center" }}>
              <img alt="Loading..." src={loadingGif} style={{ height: 150 }} />
            </td>
          </tr>
        )}

        {/* Si no está cargando y OPEN es true */}
        {!LOADING && OPEN && (
          <>
            {/* Caso normal: CODCLI distinto de 88888 */}
            {CODCLI !== 88888 && (
              <>
                <tr style={{ backgroundColor: "#339999", color: "#fff" }}>
                  <td style={{ width: "10%" }}>CÓDIGO ARTÍCULO</td>
                  <td style={{ width: "45%" }}>DESCRIPCIÓN ARTÍCULO</td>
                  <td style={{ width: "15%" }}>UDS. ENVIADAS/TOTALES</td>
                  <td style={{ width: "15%" }}>ALBARÁN</td>
                  <td style={{ width: "15%" }}>FECHA</td>
                </tr>
                {LINEAS.map(
                  ({ CODART, DESCLIN, RESTO_VIRTUALES, VIRTUALES, SERIE, NUMDOC, FECHA }, idx) => (
                    <tr key={idx}>
                      <td style={{ width: "10%" }}>
                        {CODART}
                      </td>
                      <td style={{ width: "45%" }}>{DESCLIN}</td>
                      <td style={{ width: "15%" }}>
                        {RESTO_VIRTUALES}/{VIRTUALES}
                      </td>
                      <td style={{ width: "15%" }}>{`${SERIE}/${NUMDOC}`}</td>
                      <td style={{ width: "15%" }}>{FECHA}</td>
                    </tr>
                  )
                )}
              </>
            )}

            {/* Caso especial: CODCLI === 88888 con estructura anidada */}
            {CODCLI === 88888 && (
  <>
    <tr style={{ backgroundColor: "#339999", color: "#fff" }}>
      <td style={{ width: "20%" }}>CÓDIGO ARTÍCULO</td>
      <td style={{ width: "55%" }}>DESCRIPCIÓN ARTÍCULO</td>
      <td style={{ width: "25%" }}>UDS. ENVIADAS/TOTALES</td>
    </tr>
    {LINEAS.map((linea, indexCar5) => {
      // Si CAR5 es nulo, no se muestra nada
      if (!linea.CAR5) return null;
      return (
        <React.Fragment key={indexCar5}>
          {/* Fila principal (nivel 1) */}
          <tr
            style={{ backgroundColor: "#FFC107", cursor: "pointer" }}
            onClick={() => {
              // Alterna la propiedad OPEN de la línea
              linea.OPEN = !linea.OPEN;
              this.setState({ state: this.state });
            }}
          >
            <td style={{ width: "20%" }}></td>
            <td style={{ width: "55%" }}>{linea.CAR5}</td>
            <td style={{ width: "25%" }}>
              {linea.RESTO_VIRTUALES}/{linea.VIRTUALES}
            </td>
          </tr>

          {/* Nivel 2: Productos anidados */}
          {linea.OPEN &&
            linea.PRODUCTOS &&
            linea.PRODUCTOS.map((producto, indexCar8) => (
              <React.Fragment key={indexCar8}>
                <tr
                  style={{ backgroundColor: "#FFECB3", cursor: "pointer" }}
                  onClick={() => {
                    // Alterna la propiedad OPEN del producto
                    producto.OPEN = !producto.OPEN;
                    this.setState({ state: this.state });
                  }}
                >
                  <td style={{ width: "20%" }}></td>
                  <td style={{ width: "55%" }}>{producto.CAR8}</td>
                  <td style={{ width: "25%" }}>
                    {producto.RESTO_VIRTUALES}/{producto.VIRTUALES}
                  </td>
                </tr>

                {/* Nivel 3: Subproductos o detalles */}
                {producto.OPEN &&
                  producto.PRODUCTOS &&
                  producto.PRODUCTOS.map(
                    (
                      { CODART, DESCLIN, RESTO_VIRTUALES, VIRTUALES, SERIE, NUMDOC, FECHA },
                      subIdx
                    ) => (
                      <tr key={subIdx}>
                        <td style={{ width: "20%" }}>{CODART}</td>
                        <td style={{ width: "55%" }}>{DESCLIN}</td>
                        <td style={{ width: "25%" }}>
                          {RESTO_VIRTUALES}/{VIRTUALES}
                        </td>
                      </tr>
                    )
                  )}
              </React.Fragment>
            ))}
        </React.Fragment>
      );
    })}
  </>
)}

          </>
        )}
      </tbody>
    </table>
  </div>
))}

				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;