import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	state = {
		username:'',
		password:'',
		historico: [],
		resumen:[],
		kg_totales: 0,
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	
	async getData(){
		
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		
		this.setState({loading: true });
		
		let bodyData = {
			date: tomorrow,
			yesterday: today,
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_historico",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.historico = result[0];
								this.state.resumen = result[1];
								this.state.kg_totales = 0;
								for(var i=0;i<this.state.resumen.length;i++)
									this.state.kg_totales += this.state.resumen[i].KG
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	async getLineas(index){
		
		if(this.state.historico[index].SEARCH){
			this.state.historico[index].OPEN = !this.state.historico[index].OPEN;
			this.setState({state: this.state });
			return;
		}
		
		this.state.historico[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.historico[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_sub_historico",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.historico[index].LINEAS = result[0];
									this.state.historico[index].OPEN = true;
									this.state.historico[index].SEARCH = true;
								}
								
								this.state.historico[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.historico[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	getTotalTochos(){
		var total = 0;
		
		for(var i=0;i<this.state.resumen.length;i++)
			total += this.state.resumen[i].TOCHOS;
		
		return total;
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={() => this.getData()} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>RESUMEN</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.resumen.length>0&&
				<table className="tablaTareas">
					{this.state.resumen.map(({ SERIE_MATRIZ,KG,ALIAS_MATRIZ,DESCART_MATRIZ,CODART,DESCART,TOCHOS,TIEMPO,KG_PEDIDOS },index) => (
						<tbody className="tablaTareas" style={{backgroundColor:"#FFF"}} key={index}>
							{index==0&&
								<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
									<td>
										PERFIL
									</td>
									<td>
										DESCRIPCIÓN PERFIL
									</td>
									<td>
										MATRIZ
									</td>
									<td>
										kg/h
									</td>
									<td>
										kg {this.state.kg_totales!=0&&<a> - TOTAL: {this.state.kg_totales.toFixed(0)} kg</a>}
									</td>
									<td>
										kg ALBARÁN
									</td>
									<td>
										DIFERENCIA*
									</td>
									<td>
										Nº TOCHOS - TOTAL: {this.getTotalTochos()}
									</td>
								</tr>
							}
							<tr>
								<td>
									{CODART}
								</td>
								<td>
									{DESCART}
								</td>
								<td>
									{ALIAS_MATRIZ}-{SERIE_MATRIZ}
								</td>
								<td>
									{(KG/TIEMPO).toFixed(0)} kg
								</td>
								<td>
									{KG.toFixed(0)} kg
								</td>
								<td>
									{KG_PEDIDOS>0 ? (KG_PEDIDOS).toFixed(0)+' kg' : ''}
								</td>
								<td>
									{KG_PEDIDOS>0 ? (KG_PEDIDOS-KG).toFixed(0)+' kg' : ''}
								</td>
								<td>
									{TOCHOS}
								</td>
							</tr>
						</tbody>
					))}
				</table>
			}
			<p>*DIFERENCIA = (kg albaranados) - (kg extrusionados)</p>
			<h2 style={{marginBottom:25,marginTop:50}}>HISTÓRICO</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.historico.length>0&&
				<a>
					<table className="tablaEntrada">
					{this.state.historico.map(({ NUMDOC,SERIE,CODART_MATRIZ,SERIE_MATRIZ,ALIAS_MATRIZ,DESCART_MATRIZ,CODEMP,EMPLEADO,FECHA_INICIO,FECHA_FIN,CODART,DESCART,KG,INDICACIONES,OPEN,CARGANDO,LINEAS,SEARCH },index) => (
						<tbody className="tablaTareas" style={{backgroundColor:"#FFF"}} key={index}>
							{index==0&&
								<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
									<td>
										NUM.DOC.
									</td>
									<td>
										FECHA INICIO
									</td>
									<td>
										FECHA FIN
									</td>
									<td>
										PERFIL
									</td>
									<td>
										DESCRIPCIÓN PERFIL
									</td>
									<td>
										MATRIZ
									</td>
									<td>
										CANTIDAD
									</td>
									<td>
										EMPLEADO
									</td>
									<td>
										INDICACIONES
									</td>
								</tr>
							}
							<tr onClick={() => this.getLineas(index)}>
								<td>
									{SERIE}/{NUMDOC}
								</td>
								<td>
									{FECHA_INICIO}
								</td>
								<td>
									{FECHA_FIN}
								</td>
								<td>
									{CODART}
								</td>
								<td>
									{DESCART}
								</td>
								<td>
									{ALIAS_MATRIZ}-{SERIE_MATRIZ}
								</td>
								<td>
									{KG.toFixed(0)} kg
								</td>
								<td>
									{EMPLEADO}
								</td>
								<td>
									{INDICACIONES}
								</td>
							</tr>
							{OPEN&&
								<tr>
									<td colspan="9" style={{padding:0}}>
										<table className="tablaTareas">
											{LINEAS.map(({ ID_TOCHO,CANTIDAD,MEDIDA,LOTE_PERFIL,ID_EXTRUSION,LOTE_TOCHO,PESO_TOCHO,KG,TOCHO },index) => (
												<tbody key={index}>
													{index==0&&
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
															<td>
																KG EXTRUIDOS
															</td>
															<td>
																TOCHO
															</td>
															<td>
																COLADA
															</td>
															<td>
																LOTE PERFIL
															</td>
															<td>
																MEDIDA TOCHO
															</td>
															<td>
																CANTIDAD TOCHOS
															</td>
														</tr>
													}
													<tr style={{backgroundColor:"#eee",color:"#000",cursor:"auto"}}>
														<td>
															{KG.toFixed(0)} kg
														</td>
														<td>
															{TOCHO}
														</td>
														<td>
															{LOTE_TOCHO}
														</td>
														<td>
															{LOTE_PERFIL}
														</td>
														<td>
															{MEDIDA}
														</td>
														<td>
															{CANTIDAD}
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</td>
								</tr>
							}
							{CARGANDO&&
								<tr style={{cursor:"auto"}}>
									<td colspan="9">
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;